import consumer from "./consumer"


let submit_messages;
let resource_id;

var room_id = $('#chatroom').attr('data-room-id');


// consumer.subscriptions.create({
//   channel: "ResourceChannel",
//   resource: document.querySelector('head').dataset.resourceId
// }, {
//     connected() {
//       // Called when the subscription is ready for use on the server
//       console.log("Connected to the Resource " + $('#resource_messages').attr('data-resource-id'))
//     },

//     disconnected() {
//       // Called when the subscription has been terminated by the server
//     },

//     received(data) {
//       // Called when there's incoming data on the websocket for this channel
//       console.log(data)
//       $('#resource_messages').append('<div class="message"> <strong>' + data.user + '</strong>: ' + data.body + '</div>')
//       // let message = '<a class="list-group-item list-group-item-action d-flex align-items-center justify-content-between" href="/messages/15"> <div> <strong>alice</strong>' +
//       //  '<small class="text-muted">' + data. '</small> next i restarted the server for any changes </div> <span class="btn btn-sm btn-outline-secondary"> <i class="fas fa-chevron-right fa-fw"></i> </span> </a> </div>'
//       console.log("Recieving:")
//       console.log(data.content)
//     }
//   });

$(document).on('turbolinks:load', function () {
  // TODO look for resource id from page and subscribe to the channel of that resource
  submit_messages()


  if ($("#chat-container").length > 0) {
    consumer.subscriptions.create({
      channel: "RoomChannel",
      id: $('#chatroom').attr('data-room-id')
    }, {
        connected() {
          // Called when the subscription is ready for use on the server
          console.log("Verbunden mit Chatroom: " + $('#chatroom').attr('data-room-id'))
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          console.log(data.message)
          console.log(data)

          var message_date = new Date(data.create_time).toLocaleString("de-AT");

          var current_user_id = $('#chatroom').attr('data-room-user');
          var fullhost = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');

          if (data.user_id == current_user_id) {
              var msg_class =  "";
          } else {
              var msg_class =  "chat-left";
          }

          $('#chatroom').append('<div class="chat '+ msg_class +'"> <div class="chat-avatar"> <a class="avatar" data-toggle="tooltip" href="#" data-placement="right" title="" data-original-title=""> <img src="' + fullhost + data.user_avatar_url + '"></a> </div> <div class="chat-body"> <div class="chat-content"> <p>' + data.message + '</p><p class="small">' + data.username + ' | ' + message_date +  '</p> </div> </div>')

          $('#chat-container').stop().animate({
            scrollTop: $('#chat-container')[0].scrollHeight
          }, 800);

          // let message = '<a class="list-group-item list-group-item-action d-flex align-items-center justify-content-between" href="/messages/15"> <div> <strong>alice</strong>' +
          //  '<small class="text-muted">' + data. '</small> next i restarted the server for any changes </div> <span class="btn btn-sm btn-outline-secondary"> <i class="fas fa-chevron-right fa-fw"></i> </span> </a> </div>'
          console.log("Recieving:")
        }
      });
  };




})

resource_id = function () {
  $('.chat').attr('data-room-id')
}

submit_messages = function () {
  $('#message_body').on('keydown', function (event) {
    if (event.keyCode == 13) {
      $('input').click()
      event.target.value = ''
      event.preventDefault()
    }
  })
}
