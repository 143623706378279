// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")




// custom js files


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


$(document).ready(function() {


  //var url = window.location;
  var url = location.pathname.split('/')[1];
  //alert(window.location);
  //alert(window.location.href);
  //alert(location.pathname);
  //alert(window.location.pathname.split('/')[1])
  // Will only work if string in href matches with location
  //$('ul.navigation-main a[href="'+ url +'"]').parent().addClass('active');

  // Will also work for relative and absolute hrefs
  $('ul.subnav li a').filter(function() {
      return this.pathname.split('/')[1] == url;
  }).parent().addClass('active').parent().parent().addClass('open');

  $('ul.navigation-main li.single a').filter(function() {

      return this.pathname.split('/')[1] == url;
  }).parent().addClass('active');

  $('#videoplayer').bind('contextmenu',function() { return false; });

  $('.custom-file input').change(function (e) {
      //$(this).next('.custom-file-label-leer').html(e.target.files[0].name);
      $(this).next('.custom-file-label').html(e.target.files[0].name);
  });



    function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
          $('#preview').addClass('img-thumbnail');
          $('#preview').attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]); // convert to base64 string
      }
    }

    $("#user_avatar").change(function() {
      readURL(this);
    });

    $("#company_companylogo").change(function() {
      readURL(this);
    });

    $(function() {
      $('#new_room_message').on('ajax:success', function(a, b,c ) {
        $(this).find('input[type="text"]').val('');
      });
    });



});
