import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    console.log("ready for receive Nots");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    switch (data.typ) {
      case "chat":
        var room_id = $('#chatroom').attr('data-room-id');
        if (room_id == data.chatroom_id) {  //wenn User im Channel ist - neu angelegte Notification in der DB Löschen
          $.ajax({
            type: "POST",
            url: "/notifications/delete_new",
            data: { user_id: data.to_user, room_id: data.chatroom_id  },
            dataType: 'json'
          });
        } else { //wenn User nicht im Channel ist - Notification an der Oberfläche anlegen
          if ($("#notification-navbar-link").hasClass("bell-shake")) {
            if ($(".notification-chat").length > 0) {

              $(".notification-chat").each(function(){
                  if (data.chatroom_id == $(this).attr('data-room-id')) {
                    console.log("nothing to do");
                  } else {
                    var current_nots = parseInt($(".badge-pill").html());
                    var new_nots = current_nots + 1;
                    $(".badge-pill").html(new_nots);
                    $("#notification-list").append('<a href="/rooms/'+ data.chatroom_id +'" class="notification-chat" data-room-id="' + data.chatroom_id +'">\
                                              <div class="media">\
                                                  <div class="media-left align-self-center"><i class="ft-share info font-medium-4 mt-2"></i></div>\
                                                  <div class="media-body">\
                                                      <h6 class="media-heading info">'+ data.message +'</h6>\
                                                      <p class="notification-text font-small-3 text-muted text-bold-600">Im Chat '+ data.chatroom +'</p>\
                                                  </div>\
                                              </div>\
                                          </a>');

                  }
              });
            }
          } else {
            $("#notification-navbar-link").addClass("bell-shake")
            $(".badge-pill").addClass("badge-glow badge-danger");
            $(".badge-pill").html('1');
            $("#notification-list").append('<a href="/rooms/'+ data.chatroom_id +'" class="notification-chat" data-room-id="' + data.chatroom_id +'">\
                                      <div class="media">\
                                          <div class="media-left align-self-center"><i class="ft-share info font-medium-4 mt-2"></i></div>\
                                          <div class="media-body">\
                                              <h6 class="media-heading info">'+ data.message +'</h6>\
                                              <p class="notification-text font-small-3 text-muted text-bold-600">Im Chat '+ data.chatroom +'</p>\
                                          </div>\
                                      </div>\
                                  </a>');
          }
        }

        break;
      default:
        console.log("nothing to do");
    }


  },

  away() {
    // Calls `AppearanceChannel#away` on the server.

  },


});
